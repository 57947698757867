@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Syloti+Nagri&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    color: white;
}

body {
    font-family: 'Open Sans', sans-serif;
    background-color: #000220;
}

.is-visible {
    display: block;
}

.is-hidden {
    display: none;
}

.logo {
    padding-top: 0rem;
    margin-left: 20px;
    overflow: hidden;
    -webkit-scrollbar: none;
}

.logo-image {
    width: 13em;
}

header {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    width: 100%;
    padding: 0 2rem;
    background-color: transparent;
    color: #fff4f4;
    z-index: 10000000000;
}

nav {
    position: absolute;
    display: flex;
    flex-direction: row;
    padding-top: 1rem;
    right: 3rem;
}

nav a {
    margin: 0 1.3rem;
    padding: 0 .3rem;
    text-decoration: none;
    color: rgb(176, 176, 176);
    position: relative;
}

nav a:after {
    content: "";
    position: absolute;
    background-color: #f2457f;
    height: 3px;
    width: 0;
    left: 0;
    bottom: -.5rem;
    transition: .4s;
}

nav a:hover:after {
    width: 100%;
}

nav a:hover {
    color: white;
}

header .nav-btn {
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    visibility: hidden;
    opacity: 0;
}

.home {
    width: 100%;
    height: 100%;
}

.home {
    background: url('https://nova-iptv.net/media/novaiptv-bg.jpg');
    background-size: 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
}

.first-section {
    margin: 3rem 1rem;
    padding: 2rem;
    left: 0;
    position: absolute;
    font-size: 55px;
}

.typewriting {
    color: #7334A6;
    text-decoration: underline;
}

.second-section {
    position: absolute;
    margin-top: 6rem;
    margin-right: 4.5rem;
    padding: 2rem 0;
    right: 0;
    transform: translateY(0%);
    font-size: 40px;
    direction: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.second-section-one {
    direction: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
}

.second-section-two {
    direction: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
}

.image-section {
    margin: .3rem;
    height: 200px;
    width: 210px;
}

.section {
    margin: 0 2rem 0 0;
    display: inline-block;
}


.features-container {
    position: relative;
    min-height: 100vh;
    max-width: 100vw;
    background-image: url('https://nova-iptv.net/media/novaiptv-bg-2.jpg');
    background-size: cover;
}

.intro h1 {
    padding-top: 3.4rem;
    color: white;
    text-align: center;
    font-size: 40px;
}

.intro h3 {
    color: #c8c6c6;
    text-align: center;
    font-size: 25px;
}

.feature {
    position: relative;
    margin-top: 2.4rem;
    margin-left: 2rem;
    cursor: grab;
    height: 430px;
    max-width: 345px;
    border: 1px #2e3768 solid;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.cover-photo {
    position: relative;
    margin: 2.4rem 0;
}

.title h1 {
    font-size: 24px;
    font-weight: 3rem;
}

.description p {
    color: #dad5d5;
    text-align: center;
    font-size: 19px;
    padding: 1rem 1rem;
}


.plans {
    min-height: 125vh;
    max-width: 100vw;
    background-color: #010221;
    padding: 0 0 0 2rem;
}

.plans .title h1 {
    font-size: 40px;
    text-align: center;
    padding-top: 3.5rem;
}

.plans .description h3 {
    text-align: center;
    color: #C8C6C6;
    font-size: 25px;
    margin: 0 3.4rem;
}

.first-cards {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: #02021E;
    margin: 3.5rem auto;
    max-width: 90vw;
}

.plan-name {
    font-size: 27px;
    margin-bottom: .7rem;
}

.plan-image {
    height: 90px;
    width: 90px;
    margin-bottom: 20px;
}

.plans-list {
    margin: 2.4rem 0;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.plans-card {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    box-shadow: rgba(159, 157, 157, 0.35) 2px 10px 20px;
    min-height: 85vh;
    width: 30vw;
    padding: 2rem 0rem;
    margin: 1.3rem 0 0 0;
}


.plan-price {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.old-price {
    font-size: 23px;
    color: grey;
}

.current-price {
    font-size: 40px;
    color: #BA46E2;
    font-weight: 900;
}

.no_connections {
    color: #575776;
}

.plan-outline {
    padding: 0 1rem;
    margin: 1rem 0;
    border: #a6a5a5 1px solid;
    width: 18vw;
    height: 2px;
}

.caracteristic {
    margin: .3rem auto;
}

.popular {
    margin-top: 0;
    min-height: 100vh;
    width: 33vw;
    background-color: #161732;
    box-shadow: rgba(185, 185, 185, 0.25) 0px 14px 28px -20px;
    font-size: 1.1rem;
    z-index: 200;
}

.popular img {
    height: 67px;
    width: 67px;
}

.popular .caracteritics-plans {
    margin-top: .6rem;
    font-size: 1.3rem;
}

.btn {
    height: 9vh;
    width: 26vw;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .2rem;
    margin-top: 1.7rem;
    background: transparent;
    padding: .4rem .7rem;
    font-size: 20px;
    cursor: pointer;
    border: 2px white solid;
    border-radius: 2rem;
    text-align: center;
}

.btn-popular {
    background-color: #BA46E2;
    border: none;
    margin-top: 1.2rem;
    margin-bottom: .8rem;
}

.btn:hover {
    background-color: #BA46E2;
    color: white;
    transition: all .5s;
    opacity: 1;
    border: none;
}

.faq-container {
    background-color: #000220;
    position: relative;
    min-height: 85vh;
    width: 100%;
}

.faq {
    min-height: 80vh;
    width: 100%;
    background: url("./assets/backgrounds/galaxy.png");
    background-size: 55vw 90vh;
    background-repeat: no-repeat;
}

.faq-widget {
    margin-top: 3.7rem;
    position: absolute;
    left: 40%;
    float: left;
}

.faq-widget .maintitle h1 {
    font-size: 35px;
}

.faq-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.headset {
    position: fixed;
    height: 50px;
    width: 50px;
    left: 0;
    bottom: 0;
    margin: 2rem;
    padding: 10px;
    background-color: #406dff;
    border: .1px grey solid;
    border-radius: 50%;
    z-index: 1000000000000000;
}

.item {
    padding: 10px 20px;
}

.item .title {
    cursor: pointer;
    text-align: left;
    display: flex;
    flex-direction: row;
}

.item .title span {
    font-size: 24px;
    margin: 0 10px 0 0;
}

.item .title h2 {
    font-size: 22px;
    color: white;
}

.item .content {
    font-size: 17px;
    color: #9f9e9e;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.item .content.show {
    height: auto;
    max-height: 9999px;
    transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}

.catalog {
    position: relative;
    min-height: 165vh;
    width: 100vw;
    background-color: #000220;
}

.catalog-title {
    text-align: center;
    font-size: 40px;
}

.title-description {
    padding-top: 3rem;
}

.catalog-description {
    text-align: center;
    font-size: 16px;
    color: #9a9999;
}


.films-list-1 {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2.6rem;
}

.films-list-2 {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2.6rem;
}

.hero-grid {
    position: relative;
    float: left;
    overflow: hidden;
    cursor: pointer;
    margin: 20px;
    width: 250px;
    height: 400px;
}

.hero-grid-image {
    position: relative;
    display: block;
    min-height: 100%;
    max-width: 100%;
    opacity: 0.8;
}

.hero-grid-content {
    padding: 2em;
    color: #fff;
    text-transform: uppercase;
    font-size: 1.25em;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.hero-grid-content::before,
.hero-grid-content::after {
    pointer-events: none;
}

.hero-grid-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    text-indent: 200%;
    white-space: nowrap;
    font-size: 0;
    opacity: 0;
}

.hero-grid-title {
    letter-spacing: -1px;
    font-weight: 300;
    margin: 0;
    padding: 0.25em 0;
    line-height: 1;
}

.hero-grid-title span {
    font-weight: 800;
}

.hero-tag {
    display: inline-block;
    font-size: .5em;
    padding: .5em;
    background: #fff;
    color: #000;
}

.hero-grid h2,
.hero-grid p {
    margin: 0;
}

.hero-grid p {
    letter-spacing: 1px;
    font-size: 68.5%;
    max-height: 7em;
    overflow: hidden;
}

.effect-image {
    max-width: none;
    width: -webkit-calc(100% + 50px);
    width: calc(100% + 50px);
    opacity: 0.7;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: translate3d(-40px, 0, 0);
    transform: translate3d(-40px, 0, 0);
}

.effect-text {
    color: rgba(255, 255, 255, 0.8);
    opacity: 0;
    -webkit-transition: opacity 0.2s, -webkit-transform 0.35s;
    transition: opacity 0.2s, transform 0.35s;
}

.effect-move:hover .effect-image,
.effect-move:hover .effect-text {
    opacity: 1;
}

.effect-move:hover .effect-target,
.effect-move:hover .effect-image {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.effect-move:hover .effect-text {
    -webkit-transition-delay: 0.05s;
    transition-delay: 0.05s;
    -webkit-transition-duration: 0.35s;
    transition-duration: 0.35s;
}

.caracteritics-plans {
    margin-bottom: 1rem;
}

.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 1rem;
}

.payment-methods {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: 1rem;
    gap: 1rem;
}

.checkout {
    padding: 1rem;
    margin: 1rem;
    margin-bottom: 3rem;
    border: 2px #3C1161 solid;
    border-radius: 10px;
}

.checkout h2 {
    font-size: 20px;
}

.name-email-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: .2rem;
}

.name-container {
    margin-top: 1.8rem;
    display: flex;
    flex-direction: column;
    gap: .2rem;
}

.name-input {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}

.name-container input[type="text"] {
    padding: 1rem;
    height: 2rem;
    width: 30vw;
    background: #161732;
    color: white;
    border: 1.5px #BA45C1 solid;
    border-radius: .5rem;
    outline-width: none;
    transition: .7s;
}

.name-container input[type="text"]:focus {
    border: 2px #BA45C1 solid;
    width: 35vw;
    outline: none;
}

.email-container {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: .4rem;
}

.email-input {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}

.email-container input[type="email"] {
    padding: 1rem;
    height: 2rem;
    width: 30vw;
    background: #161732;
    color: white;
    border: 1.5px #BA45C1 solid;
    border-radius: .5rem;
    outline-width: none;
    transition: .7s;
}

.email-container input[type="email"]:focus {
    border: 2px #BA45C1 solid;
    width: 35vw;
    outline: none;
}

.country-number-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.country-select {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.country-select select {
    text-align: center;
    outline: none;
    color: black;
    border-radius: 5px;
}

.number-container {
    margin-top: 1.8rem;
    margin-left: 3rem;
    display: flex;
    flex-direction: column;
    gap: .2rem;
}

.number-input {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}

.number-container input[type="tel"] {
    padding: 1rem;
    height: 2rem;
    width: 30vw;
    background: #161732;
    color: white;
    border: 1.5px #BA45C1 solid;
    border-radius: .5rem;
    outline-width: none;
    transition: .7s;
}

.number-container input[type="tel"]:focus {
    border: 2px #BA45C1 solid;
    width: 35vw;
    outline: none;
}

.checkout-page {
    margin-bottom: 2rem;
}

.checkout-payment {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.PayPalButtons {
    width: 50vw;
}

.chosen-plan-description {
    padding: 2rem 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.tv-image {
    height: 7rem;
    width: 7rem;
}

.details {
    color: #8030A5;
}

.chosen-plan-description-caracteristics ul li {
    list-style-type: disc;
}

iframe {
    position: relative;
    background-color: white;
    border-radius: 2rem;
    min-height: 100vh;
    min-width: 86vw;
}

.payment-methods {
    width: 10rem;
    height: 4rem;
}

.link-icon {
    height: 1.2rem;
    width: 1.2rem;
}

.link {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: .5rem;
}

.thank-you-container {
    height: 100vh;
    width: 100vw;
}

.thank-you {
    position: absolute;
    padding: 3rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;
}

.thank-you h1 {
    font-size: 3rem;
    text-align: center;
}

.thank-you h3 {
    font-size: 1.4rem;
    text-align: center;
}

.cookies-container {
    margin: 3rem 2rem;
    padding-bottom: 4rem;
    line-height: 2.3rem;
}

.cookies-container h1 {
    font-size: 2rem;
    text-align: center;
}

.cookies-container p {
    font-size: 1.3rem;
    text-align: left;
}

.cookies-container a {
    text-decoration: underline;
}

.cookies-container strong {
    margin-top: 1.6rem;
    font-size: 1.3rem;
    text-align: left;
    color: greenyellow;
}

.cookies-email {
    color: magenta;
}

/* Responsive Design */
@media only screen and (max-width: 1182px) {
    .home {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .first-section {
        font-size: 43px;
    }

    .second-section {
        position: absolute;
        margin: 3rem 1rem 0 0;
        padding: 2rem;
        right: 0;
        transform: translateY(0%);
        font-size: 40px;
        direction: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .image-section {
        height: 190px;
        width: 190px;
    }

    .feature {
        position: relative;
        margin-top: 2.4rem;
        margin-left: 2rem;
        cursor: grab;
        height: 430px;
        width: 300px;
        border: 1px #2e3768 solid;
        border-radius: 1rem;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .catalog {
        padding: 0 1em 0 1em;
    }

    .films-list-1 {
        gap: 1.5rem;
        margin-bottom: 0;
    }

    .films-list-2 {
        gap: 1.5rem;
    }


}

@media only screen and (max-width: 1024px) {
    header .nav-btn {
        visibility: visible;
        opacity: 1;
    }

    header nav {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 3.5rem;
        background-color: #1D2951;
        transition: 1s;
        transform: translateY(-100vh);
    }

    header .responsive_nav {
        transform: none;
    }

    nav a:hover {
        color: white;
    }

    nav .nav-close-btn {
        position: absolute;
        top: 2rem;
        right: 2rem;
        color: white;
    }

    .plans {
        margin-top: 5rem;
    }

    .plans-list {
        margin: 0;
    }

    .first-cards {
        background-color: #02021E;
        margin: 3.5rem auto;
        width: 100vw;
    }

    .plans-card {
        margin: 2rem auto;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border: #b0b0b0 2px solid;
        box-shadow: rgba(159, 157, 157, 0.35) 2px 10px 20px;
        border-radius: 16px;
        min-height: 87vh;
        min-width: 35vw;
        padding: 1rem 0rem;
    }

    .popular {
        height: 95vh;
        width: 40vw;
    }

    .btn {
        height: 10vh;
        width: 50vw;
    }

    .btn-popular {
        height: 12vh;
        width: 55vw;
    }

    .catalog {
        padding: 0 1em;
        margin-bottom: 0;
    }

    .films-list-1 {
        gap: 1rem;
        margin-bottom: 0;
    }

    .films-list-2 {
        margin-bottom: 0;
        gap: 1rem;
    }

    .hero-grid {
        margin: 10px;
        width: 200px;
        height: 350px;
    }

    .faq-container {
        margin-top: 0;
    }

    .first-section {
        font-size: 38px;
    }

    .second-section {
        position: absolute;
        padding: 2rem 0 2rem 0;
        right: 0;
        transform: translateY(0%);
        font-size: 40px;
        direction: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .feature {
        position: relative;
        margin-top: 2.4rem;
        margin-left: .7rem;
        cursor: grab;
        height: 430px;
        width: 281px;
        border: 1px #2e3768 solid;
        border-radius: 1rem;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

}

@media only screen and (max-width: 800px) {
    .home {
        position: relative;
        padding-top: 3rem;
        max-height: 60vh !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    .first-section {
        position: relative;
    }

    .image-section {
        max-height: 180px;
        max-width: 180px;
    }

    .breakline {
        display: none;
    }

    .first-section h2 {
        font-size: 2rem;
    }

    .second-section {
        position: absolute;
        margin-top: 25vh;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        gap: 0;
    }

    .plans .description h3 {
        text-align: center;
        color: #C8C6C6;
        font-size: 25px;
        margin: 0;
    }

    .plans-card {
        position: relative;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: column;
        border: #b0b0b0 2px solid;
        box-shadow: rgba(159, 157, 157, 0.35) 2px 10px 20px;
        border-radius: 16px;
        min-height: 85vh;
        min-width: 80vw;
        padding: 1rem 0rem;
        margin: 2rem 0;
    }

    .catalog {
        position: relative;
        width: 100vw;
        gap: 0;
    }

    .films-list-1 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 1rem 0 1rem 0;
        gap: 0;
    }

    .films-list-2 {
        margin: 0 0 1rem 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 0rem;
    }

    .popular {
        min-height: 95vh;
    }

    .hero-grid {
        margin: 1px;
    }

    .faq {
        background: none;
        display: block;
        max-width: 100%;
    }

    .faq-widget {
        position: relative;
        display: block;
        margin: auto;
        padding: auto;
        left: 0;
    }

    .faq-container {
        margin-top: -5rem;
        max-width: 100vw;
        padding: 0;
        display: block;
    }

    .faq-widget .maintitle h1 {
        font-size: 30px;
        text-align: center;
    }

    .footer {
        max-width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 1.3rem;
    }

    .payment-methods {
        display: none;
    }

    .first-cards {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
        background-color: #02021E;
        margin: 3.5rem 0;
        width: 100vw;
    }

}


@media only screen and (max-width: 660px) {
    .home {
        height: 120vh !important;
    }

    .second-section {
        margin-top: 33vh;
    }

    .image-section {
        max-height: 145px;
        max-width: 145px;
    }

    .features-container {
        width: 100vw;
    }

    .feature {
        width: 100%;
        margin: 3rem auto;
        padding: 2rem;
    }

    .popular {
        min-height: 110vh;
    }

    .plans-card {
        position: relative;
        left: 0;
        transform: translateX(-4%);
    }
}

@media only screen and (max-height: 500px) {
    .home {
        height: 900px;
    }

    .first-section {
        margin-bottom: 4rem;
    }

    .popular {
        min-height: 150vh;
    }

    .catalog {
        min-height: 1000px;
        margin-bottom: 20px;
    }

    .faq-container {
        max-width: 100vw;
        display: flex;
        flex-direction: column;
    }

    .popular {
        min-height: 110vh;
    }

}

@media only screen and (max-width: 360px) {
    .image-section {
        height: 140px;
        width: 140px;
    }
}